<template lang="pug">
include ../pug/svg
section#profile
	Form.wrapper(ref="profileForm" :validation-schema="profileSchema" v-slot="{ values, errors, meta }")
		div.c1
			div.field.upload.profile
				button.bin.but.circ(v-if="profileUrl" type="button" @click="onProfileBin()")
					+svg(svg-filename="iconBin" aria-hidden="true" alt="Bin icon")
				figure
					img(v-if="profileUrl" :src="profileUrl" alt="")
					figcaption
						strong(data-label="Profile Photo") Profile Photo
						span Accepts: JPG ({{maxFileSizeTidy}})
						+svg(svg-filename="iconUpload" aria-hidden="true" alt="Upload icon")
				DropZone(
					ref="dropzone"
					:paramName="dzOpts.paramName"
					:url="dzOpts.url"
					:withCredentials="dzOpts.withCredentials"
					:uploadOnDrop="dzOpts.uploadOnDrop"
					:multipleUpload="dzOpts.multipleUpload"
					:maxFiles="dzOpts.maxFiles"
					:parallelUpload="dzOpts.parallelUpload"
					:acceptedFiles="dzOpts.acceptedFiles"
					:maxFileSize="maxFileSize"
					@addedFile="onProfileAdd"
					@sending="onProfileSend"
				)
			//- 	@removedFile="onProfileRemove"
					@uploaded="onProfileUploaded"
					@errorAdd="onErrorAdd"
					@errorUpload="onErrorUpload"
			div.field.req(v-if="manageRole")
				Field(name="roleId" as="select" placeholder="Unsupplied" autocomplete="off" v-model="roleId" :validateOnInput="false" v-slot="{value}" :class="{empty:!roleId}" @change="enableCheck")
					option(v-for="(value, index) in roles" :value="value.id" :selected="value&&value.id===roleId") {{terminology('user', value.slug,'singular')}}
					//-option(value="" :selected="!roleId") Unsupplied
				label {{terminology('organisation','organisation','singular')}} Role
				+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				
			div.c2.dynamic
				//-div.field
					Field(name="title" as="select" placeholder="Unsupplied" autocomplete="off" v-model="title" :validateOnInput="false" v-slot="{value}" :class="{empty:!title}" @change="enableCheck")
						option(v-for="(value, index) in titles" :value="value" :selected="value&&value===title") {{value}}
						option(value="" :selected="!title") Unsupplied
					label Title
					+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
				FieldSelect(name="title" label="Title" v-model="title" :options="titles" :errors="errors" @change="enableCheck")
				
				FieldText(name="firstName" label="First Name" v-model="firstName" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
				
				FieldText(name="lastName" label="Last Name" v-model="lastName" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
		div.c2
			FieldText(name="position" label="Position" v-model="position" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			FieldText(name="email" label="Email or Username" v-model="email" :required="true" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			FieldText(name="mobile" label="Mobile" v-model="mobile" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			//-div.field
				input(type="checkbox" v-model="zoomLicencedUser" value="true" @change="enableCheck")
				label Call User
				span.on(v-if="zoomLicencedUser") Host: &pound;12 p/m
				span.off(v-else) Attendee
			
			FieldText(name="socialLinkedin" label="LinkedIn" v-model="socialLinkedin" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			FieldText(name="socialTwitter" label="X / Twitter" v-model="socialTwitter" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			//-FieldText(name="socialFacebook" label="Facebook" v-model="socialFacebook" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			
			//-FieldText(name="socialInstagram" label="Instagram" v-model="socialInstagram" :required="false" :cancelled="cancelled" :errors="errors" @keyup="enableCheck")
			div.field
				Field(name="password" :type="passwordType" placeholder="Unsupplied" autocomplete="off" v-model="password" :validateOnInput="false" @keyup="enableCheck" @blur="enableCheck")
				button.but.eye(type="button" @click="togglePasswordType") {{ passwordState }} Password
					+svg(svg-filename="iconHide" aria-hidden="true" alt="Show icon")
				label Change Password
				span.err(:class="{hide:cancelled||!errors.password||errors.password==='-'}") {{errors.password}}
			
	span.req Required
	//-p <sup>*</sup>&pound;12 per month.
</template>

<script>
import { DropZone } from 'dropzone-vue';
import { Form, Field } from 'vee-validate';
import * as Yup from 'yup';
import YupPassword from 'yup-password';
import FieldText from '../components/FieldText';
import FieldSelect from '../components/FieldSelect';
import { createHelpers } from 'vuex-map-fields';

YupPassword(Yup); // extend yup

const { mapFields: mapUserFields } = createHelpers({
	getterType: 'getUserField',
	mutationType: 'updateUserField',
});

const { mapFields: mapUserRoleFields } = createHelpers({
	getterType: 'getUserRoleField',
	mutationType: 'updateUserRoleField',
});
	
export default {
	name: 'ManagePeopleUserProfile',
	props: ['cancelled'],
	emits: ['storeUpdated'],
	components: {
		Form,
		Field,
		DropZone,
		FieldText,
		FieldSelect,
	},
	data() {		
		const apiBaseUrl = (process.env.VUE_APP_VUE_ENV === 'development') ? process.env.VUE_APP_API_BASE_URL : window.location.origin + '/api';
		const req = 'Required';
		const inv = 'Invalid';
		const profileSchema = Yup.object().shape({
			title: Yup.string().nullable(),
			firstName: Yup.string().required(req).nullable(),
			lastName: Yup.string().required(req).nullable(),
			position: Yup.string().nullable(),
			//email: Yup.string().email(inv).required(req).nullable(),
			email: Yup.string().test('emailOrUsername', inv, (value) => {
				if (value) {
					return value.includes('@') ? Yup.string().email().isValidSync(value) : Yup.string().matches(/^[\w-]+$/).isValidSync(value); 
				}
				
				return false;
			}),
//			mobile: Yup.string().email(inv).nullable(),
			zoomUserEmail: Yup.string().email(inv).nullable(),
			password: Yup.string().label('Password').password().nullable(),
			socialFacebook: Yup.string().nullable(),
			socialLinkedin: Yup.string().nullable(),
			socialTwitter: Yup.string().nullable(),
			socialInstagram: Yup.string().nullable(),
		});
		const titles = [
			'Mr',
			'Mrs',
			'Ms',
			'Miss',
			'Mx',
			'Dr.',
			'Prof.',
			'Sir',
		];		
		return {
			zoomLicencedUser: false,
			profileSchema,
			titles,
			passwordState: 'Show',
			passwordType: 'password',
			dzOpts: {
				url: apiBaseUrl + '/media/profile',
				withCredentials: true, // pass cookies
				paramName: 'file', // match to multerUpload
				uploadOnDrop: true,
				multipleUpload: false,
				maxFiles: 1,
				parallelUpload: 1,
				acceptedFiles: ['jpg'],
			},
		};
	},
	mounted() {
		this.$store.dispatch('gui/setHeader', {
			title: this.terminology('interface', 'manage', 'singular') + ' Profile',
			backRoute: {
				text: 'Dashboard',
				name: 'Dashboard',
			},			
		});
	},
	computed: {
		...mapUserRoleFields('users', { // uses custom getter: getUserRoleField
			role: 'role',
			roleId: 'role.id',
		}),
		...mapUserFields('users', [ // uses custom getter: getUserField
			'id',
			'title',
			'firstName',
			'lastName',
			'position',
			'email',
			'password',
			'mobile',
			'userOrganisations',
			'zoomUserEmail',
			'socialFacebook',
			'socialLinkedin',
			'socialTwitter',
			'socialInstagram',
			'profileUrl',
		]),
		manageSelf() {
			return (this.$route.name === 'ManageAccountProfile');
		},
		roles() {
			if (!this.email || !Yup.string().email().isValidSync(this.email)) {
				// restrict username users to learner role
				return this.$store.state.users.userRoles.filter(r => r.id === 6);				
			}
			
			// prevent auth user asigning role higher than their own
			const authSeniority = this.$store.state.auth.user.userOrganisations[0].role.seniority;
			
			return this.$store.state.users.userRoles.filter(r => r.seniority >= authSeniority);
		},
		manageRole() {
			if (this.manageSelf) {
				// prevent self management
				return false;
				
			} else if (this.userOrganisations) {
				// prevent if auth user seniority is less than organisation user seniority or not admin or higher role
				const authSeniority = this.$store.state.auth.user.userOrganisations[0].role.seniority;
				return (authSeniority < this.role.seniority && authSeniority <= 60);
			}
			
			return false; // fallback
		},
		maxFileSize() {
			const sizes = this.$store.getters['gui/maxFileSizes'];
			return sizes['image'].max || 0;
		},
		maxFileSizeTidy() {
			return this.formatBytes(this.maxFileSize, 1);
		},
	},
	watch: {
		cancelled: {
			// parent triggered cancel
			handler(val) {
				if (!val) { // store reset complete
					const form = this.$refs.profileForm;
					const errors = form.getErrors();
					
					for(const field in form.getValues()) {
						form.setFieldTouched(field, false);
						
						if (field in errors) {
							form.setFieldError(field, '-');					
						}
					}
				}
			},
			deep: true,
		},
	},
	methods: {
		terminology(groupKey, termKey, varKey) {
			return this.$store.getters['gui/customTerm'](groupKey, termKey, varKey);
		},
		formatBytes(bytes, decimals = 1) {
			if (bytes === 0) return '0 Bytes';

//			const k = 1024;
			const k = 1000;
			const dm = decimals < 0 ? 0 : decimals;
			const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

			const i = Math.floor(Math.log(bytes) / Math.log(k));

			return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
		},
		enableCheck() {
			const meta = this.$refs.profileForm.getMeta();
			this.$emit('storeUpdated', {
				screen: 'profile', // required for account/profile
				valid: meta.valid, 
			});
		},
		onProfileAdd(item) {
			this.profileUrl = item.file.name;
			this.enableCheck();
		},
		onProfileSend(file, xhr, formData) {
			formData.append('userId', this.id);
		},
		onProfileBin() {
			this.$refs.dropzone.removeFile(this.$refs.dropzone.ids[0]);
			this.profileUrl = null;
			this.enableCheck();
		},
		togglePasswordType() {
			if (this.passwordType === 'password') {
				this.passwordType = 'text';
				this.passwordState = 'Hide';
			} else {
				this.passwordType = 'password';
				this.passwordState = 'Show';
			}
		},
	},
}
</script>

<style lang="scss">
#profile {
	.err {
		transition: opacity .3s .0s ease-in-out; // delay on appear
		&.hide {
			opacity: 0;
		}
	}
	.wrapper {
		position: relative;
		display: flex;
		align-items: flex-start;
		margin-top: 30px;
	}
	.c1 {
		width: 230px;
		min-width: 230px;
	}
	.c2 {
		flex-grow: 1;
		display: flex;
		flex-wrap: wrap;
		.field {
			margin: 0 0 25px 15px;
		}
		&:not(.dynamic):before {
			content: '';
			display: block;
			height: 60px;
			margin-bottom: 25px;
			width: 100%;
		}
	}
	@media screen and (max-width: 519px) {
		.dynamic {
			position: relative;
			.field {
				margin-left: 0;
			}
		}
		.field {
			width: 100% !important;
		}
		.profile {
			margin-left: auto;
			margin-right: auto;
		}
	}
	@media screen and (min-width: 520px) {
		.dynamic {
			position: absolute;
			top: 0;
			left: 230px;
			right: 0;
		}
	}
	@media screen and (max-width: 759px) {
		.c1 {
			width: 100%;
			.field:not(.profile) {
				max-width: none;
			}
		}		
		.c2:not(.dynamic) {
			justify-content: space-between;
			&:before {
				display: none;
			}
			.field {
				margin-left: 0;
				max-width: none;
				width: calc(50% - 10px);
			}
		}
		form {
			flex-direction: column;
		}
	}
	@media screen and (min-width: 760px) and (max-width: 1264px) {
		.c2:not(.dynamic) {
			margin-top: 85px;
			&:before {
				width: 50%;
			}
		}
		.c2 .field {
			width: calc(50% - 15px);
		}
	}
	@media screen and (min-width: 1265px) {
		.c2 .field {
			width: calc(33.33% - 15px);
		}
	}
}
</style>

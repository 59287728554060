<template lang="pug">
include ../pug/svg
div.field
	Field(:name="name" as="select" v-model="value" :required="required" :disabled="disabled" :class="{empty:!value}" @change="change")
		option(value="" :selected="!value") {{placeholder}}
		option(v-if="optionsAsObjects" v-for="(option, index) in options" :value="option.value" :selected="option.value===value") {{option.option}}
		option(v-else v-for="(option, index) in options" :value="option" :selected="option===value") {{option}}
	label {{label}}
	+svg(svg-filename="iconArrow" aria-hidden="true" alt="Arrow icon")
	span.err(:class="{hide:cancelled||!errors[name]||errors[name]==='-'}") {{errors[name]}}
</template>

<script>
import { Field } from 'vee-validate';

export default {
	name: 'FieldSelect',
	props: {
		modelValue: [String, Number],
		options: Array,
		name: {
			type: String,
			default: null
		},
		label: String,
		errors: {
			type: Object,
			default: () => ({})
		},
		cancelled: {
			type: Boolean,
			default: false
		},
		required: {
			type: Boolean,
			default: null
		},
		placeholder: {
			type: String,
			default: 'Unsupplied'
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},
	emits: ['update:modelValue','change'],
	components: {
		Field,
	},
	computed: {
		optionsAsObjects() {
			return typeof this.options[0] === 'object';
		},
		value: {
			get() {
				return this.modelValue;
			},
			set(value) {
				this.$emit('update:modelValue', value);
			}
		},
	},
	methods: {
		change(event) {
			this.$emit('change', event);			
		},
	},
}
</script>

<style lang="scss">
</style>
